import React from 'react'
import { Component } from 'react';
import {Helmet} from "react-helmet";
import './fgo.css';
import axios from 'axios';

class FgoPage extends Component{
    constructor(props){
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.state={
            location: 'Algonquin, US',
            message: <span className="unfinished-text">I can't get the weather right now but it is Fate/Grand Order time.</span>,
            autoComplete: [],
            searchTerms: '',
            acElements: <></>,
            active: -1
        }
    }
    handleKeyDown(e) {
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38 && this.state.active > 0) {
          this.setState({active: this.state.active-1}, ()=>(this.renderAutoComplete()))
        } else if (e.keyCode === 40 && this.state.active < this.state.autoComplete.length - 1) {
          this.setState({active: this.state.active+1}, ()=>(this.renderAutoComplete()))
        }else if(e.keyCode === 13 && this.state.active >= 0){
            e.preventDefault();
            let data = this.state.autoComplete[this.state.active]
            this.setSearchTerms(this.regionToText(data))
            this.clearAutoComplete()
        }
      }
    regionToText(data){
        if(data.country==='United States of America'){
            return data.name + ", " + data.region;
        }else{
            return data.name + ", " + data.country;
        }
    }

    async getWeather(){
        console.log(this.state.location)
        let weatherOptions = {
            method: 'GET',
            url: 'https://weatherapi-com.p.rapidapi.com/current.json',
            params: {q: this.state.location},
            headers: {
                'X-RapidAPI-Key': process.env.REACT_APP_RapidAPI_Key,
                'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com'
            }
        }
        try{
            let response = await axios.request(weatherOptions);
            this.setState({message: <span className="unfinished-text">It is {response.data.current.temp_f}°F outside in {response.data.location.name}, so stay inside and play Fate/Grand Order.</span>})
        }catch(error){
            console.error(error)
        }
    }
    removeActive(){
        this.setState({active: -1}, ()=>{this.renderAutoComplete()})
    }
    componentDidMount(){
        this.getWeather()
    }
    changeLocation(newLoc){
        if(newLoc==='') return;
        this.setState({location: newLoc}, ()=>{
        // console.log("location change to ", this.state.location)
        this.getWeather()
        })
    }
    setSearchTerms(input){
        this.setState({searchTerms: input})
    }
    clearAutoComplete(){
        this.setState({autoComplete: [], acElements: <></>, active:-1})
    }
    renderAutoComplete(){
        this.setState({acElements:
        <div id="autocomplete-list" className='autocomplete-items'>
            {this.state.autoComplete.map((data, idx)=>{
                let classStr = ""
                if(this.state.active===idx){
                    classStr += "autocomplete-active"
                }
                return(
                <div className={classStr} key={"auto_complete_" + String(idx)} onClick={()=>{this.setSearchTerms(this.regionToText(data)); this.clearAutoComplete()}}>
                    <span>{this.regionToText(data)}</span>
                </div>
                )
            })}
        </div>
        })
    }
    async genAutoComplete(text){
        this.setSearchTerms(text)
        if(text==='') return;
        let options = {
            method: 'GET',
            url: 'https://weatherapi-com.p.rapidapi.com/search.json',
            params: {q: text},
            headers: {
                'X-RapidAPI-Key': process.env.REACT_APP_RapidAPI_Key,
                'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com'
            }
        }
        try{
            let response = await axios.request(options);
            this.setState({autoComplete: response.data, active: -1}, ()=>{this.renderAutoComplete()})
        }catch(error){
            console.error(error)
        }
    }
    render(){
        return (<>
            <Helmet>
                <link rel="icon" href="assets/cup.png"/>
                <meta name="description" content="Can literally tell you the temperature outside." />
                <title>fgo moment</title>
            </Helmet>
            <main className="fgo-main">
                <div className="center">
                <div className="unfinished-box" >
                {this.state.message}
                </div>
                <div>
                    <h2 className="unfinished-text smaller">Should you play Fate/Grand Order or go outside? Enter your location to find out!</h2>
                    <form autoComplete="off" onKeyDown={this.handleKeyDown} onSubmit={(event)=>{event.preventDefault(); console.log(event.target[0].value); this.changeLocation(event.target[0].value)}}>
                    <div className='autocomplete'>
                        <input placeholder="Location" value={this.state.searchTerms} onClick={()=>(this.removeActive())} onChange={(event)=>(this.genAutoComplete(event.target.value))}></input>
                        {this.state.acElements}
                    </div>
                    <input type="submit"/>
                    </form>
                </div>
                </div>
            </main>
            </>)
    }
}

export default FgoPage;