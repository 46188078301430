import React from 'react'
import { Component } from 'react';
import {Helmet} from "react-helmet";

import "./christmas.css";


class ChristmasPage extends Component{
    generateChristmas(){
        let str = "CHRISTMAS!!!"
        let result = []
        for (let i = 0; i < str.length; i++) {
            if(i%2 === 0){
                result.push(<span className="red" key={i}>{str[i]}</span>)
            }else{
                result.push(<span className="green" key={i}>{str[i]}</span>)
            }
        }
        return result
    }
    render(){
        return (<>
            <Helmet>
                <link rel="icon" href="assets/cup.png"/>
                <meta name="description" content="Can literally tell you the temperature outside." />
                <title>where is your spirit?</title>
            </Helmet>
            <main className="christmas-content">
                <div className="center">
                    <a color="unset" href="https://www.youtube.com/watch?v=2RtI5UEZlzU">
                        {this.generateChristmas()}
                    </a>
                </div>
            </main>
            </>)
    }
}

export default ChristmasPage;