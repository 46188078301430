
import React from 'react';
import {Helmet} from "react-helmet";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import HomePage from './homePage/home';
import FgoPage from './other_pages/fgo';
import HarthosPage from './other_pages/harthos';
import ChristmasPage from './other_pages/christmas';

import './App.css';

import {Amplify} from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

function App() {
  return (
    <>
    <Helmet>
        <meta name="description" content="Ian's Website" />
        <title>ian's website</title>
    </Helmet>
    <Router>
      <div className="App">
        <div className="App-content">
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/fgo" element={<FgoPage/>}/>
          <Route path="/harthos" element={<HarthosPage/>}/>
          <Route path="/christmas" element={<ChristmasPage/>}/>
        </Routes>
        </div>
      </div>
    </Router>
    </>
  );
}

export default App;
