import React from 'react'
import { Component } from 'react';
import {Helmet} from "react-helmet";

import './home.css';
import '../other_pages/harthos.css'
import StarSky from "react-star-sky"

class HomePage extends Component{
render(){
return (<>
    <Helmet>
        <link rel="icon" href="assets/icon.png"/>
        <meta name="description" content="Home page for Ian's website." />
        <title>ian was here</title>
    </Helmet>
    <main className="home-content" id="home">
      <StarSky
        // debugFPS={true}
        isPageBackground={true}
        // shuffle={0}
        // starColor='white'
        frameRate={30}
      />
    <div className="sun"></div>
    <div className="mercury planet">
    </div>
    <div className="venus planet">
        <h2>Projects</h2>
        <a href="/fgo" className="center unfinished-text"> Should I play Fate/Grand Order today?</a>
    </div>
    <div className="earth planet">
        <h2>About Me</h2>
        <a href="/harthos" className="center harthos-text"> Is it Harthos Thursday?</a>
        <div>
        <p>I am a person who does things.
            THERE IS A LOT OF TEXT HERE: text text text text text text text text text 
            text text text text text text text text text text text text text text 
            text text text text text text text text text text text text text text text text 
            text text text text text text text text text text 
            text text text text text text text text text text text 
            text text text text text text text text text text text text text 
            text text text text text text text text text text </p>
        </div>
    </div>
    <div className="moon planet">
        <h3>Contact Me</h3>
        <h4 className="center">isong.projects@gmail.com</h4>
    </div>
    <div className="mars planet">
        <h2>Featured Pages</h2>
    </div>
    </main>
    {/*
        <div className="single-column-box" >
        <img className="unfinished-img" src="assets/sandwich.JPG" alt="dumb text"></img>
        <a href="/voting" className="unfinished-text"> Click for Rainstar 2023 Voting</a>
        <a href="/harthos" className="harthos-text"> Is it Harthos Thursday?</a>
        <a href="/fgo" className="unfinished-text"> Should I play Fate/Grand Order today?</a>
        </div>
        </main> */}
    </>)
}
}

export default HomePage;