import React from 'react'
import { Component } from 'react';
import {Helmet} from "react-helmet";
import StarSky from "react-star-sky"

import './harthos.css';

class HarthosPage extends Component{
    isItHarthosTh(dayOfTheWeek, d20){
        let classStr = "center harthos-text";
        if(d20===20) classStr += " nat-twenty";
        if(dayOfTheWeek===4){
            return(<span className={classStr}>Here he comes.</span>)
        }else{
            return(<span className={classStr}>Harthos will come.</span>)
        }
    }
    genBackground(d20){
        let color = "white";
        if(d20===20){
        color = "rainbow";
        }
        return <StarSky
        // debugFPS={true}
        isPageBackground={true}
        // shuffle={0}
        starColor={color}
        frameRate={30}/>
    }
    render(){
        let d = new Date();
        let dayOfTheWeek = d.getDay();
        let d20 = Math.floor(Math.random() * 20)+1;
        return (
                <>
                <Helmet>
                    <link rel="icon" href="assets/d20.png" />
                    <meta name="description" content="Is it harthos tuesday?" />
                    <title>{String(d20)}</title>
                </Helmet>
                {this.genBackground(d20)}
                <main>
                    <div className="single-column-box" >
                    {this.isItHarthosTh(dayOfTheWeek,d20)}
                    </div>
                </main>
                </>
                )
    }
}

export default HarthosPage;